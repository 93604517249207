import { FC } from 'react';

interface Props {
	width?: number;
	height?: number;
}

export const OceanflixMiniLogo: FC<Props> = ({ width, height }) => {
	return (
		<img
			className='rounded-circle d-flex'
			style={{ margin: '10px auto' }}
			height={height || 'auto'}
			src='/images/mini-logo.jpg'
			alt='Oceanflix Logo'></img>
	);
};

const OceanflixLogo: FC<Props> = ({ width, height }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			version='1.1'
			width={width || 2267.75}
			height={height || 50}
			viewBox='0 0 2267.75 341.406'>
			<defs>
				<clipPath id='clip_0'>
					<path transform='matrix(1,0,0,-1,0,341.406)' d='M0 341.406H2267.748V0H0Z' />
				</clipPath>
			</defs>
			<g id='layer_1' data-name='Camada 1'>
				<g clipPath='url(#clip_0)'>
					<path
						transform='matrix(1,0,0,-1,242.0078,170.7015)'
						d='M0 0C0 46.045-30.157 92.484-88.462 92.484-146.766 92.484-176.923 46.045-176.923 0-176.923-46.444-146.766-92.889-88.462-92.889-30.157-92.889 0-46.444 0 0M54.689 0C54.689-81.369-5.226-144.086-88.462-144.086-171.698-144.086-231.613-81.369-231.613 0-231.613 81.373-171.698 143.691-88.462 143.691-5.226 143.691 54.689 81.373 54.689 0'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,554.8857,233.8177)'
						d='M0 0 45.033-29.373C19.702-61.131-19.703-80.97-66.75-80.97-149.987-80.97-209.901-18.253-209.901 63.116-209.901 144.489-149.987 206.808-66.75 206.808-19.703 206.808 19.702 187.358 45.033 155.206L0 125.833C-14.877 143.691-37.396 155.601-66.75 155.601-125.055 155.601-155.212 109.161-155.212 63.116-155.212 16.672-125.055-29.772-66.75-29.772-37.396-29.772-14.877-17.863 0 0'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,813.9521,259.6205)'
						d='M0 0V-50.01H-166.069V227.848H-3.619V177.833H-111.788V115.115H-12.867V65.101H-111.788V0Z'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,1438.374,31.772797)'
						d='M0 0V-277.857H-49.455L-174.111-94.075V-277.857H-228.801V0H-179.336L-54.69-183.782V0Z'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,900.7832,224.18881)'
						d='M0 0-31.34-85.935H26.488L60.385 12.692C35.798 17.058 15.788 10.259 0 0'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,1075.3272,255.7084)'
						d='M0 0 18.701-54.415H76.529L53.659 8.297C38.923 1.051 20.866-3.337 0 0'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,982.6934,148.86751)'
						d='M0 0 21.288 61.939 51.856-27.003C77.189-39.04 95.018-42.403 111.018-38.393L54.618 116.255H-12.043L-52.66 4.882C-37.146 8.079-19.466 7.468 0 0'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,858.9404,244.48471)'
						d='M0 0V50.988C0 50.988 56.908 137.111 142.913 86.287 228.918 35.463 244.368 51.217 301.275 107.858V56.871C301.275 56.871 243.596-31.54 153.213 31.377 62.83 94.295 14.936 16.081 0 0'
						fill='#1aa3dd'
						fillRule='evenodd'
					/>
					<path
						transform='matrix(1,0,0,-1,1546.6338,81.78061)'
						d='M0 0V-68.885H97.561V-119.059H0V-228.56H-54.153V50.174H109.107V0Z'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,1868.7451,260.1693)'
						d='M0 0V-50.171H-161.268V228.562H-107.512V0Z'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,0,341.406)'
						d='M1970.259 31.066H1916.503V309.799H1970.259Z'
						fill='#17204c'
					/>
					<path
						transform='matrix(1,0,0,-1,2194.829,310.3402)'
						d='M0 0-61.317 95.965-122.243 0H-184.759L-94.369 141.755-177.992 278.733H-115.073L-60.92 186.354-6.767 278.733H55.748L-27.868 142.152 62.919 0Z'
						fill='#17204c'
					/>
				</g>
			</g>
		</svg>
	);
};

export default OceanflixLogo;
