import { RouteProps } from 'react-router-dom';
import { menuPages } from '../menu';
import ProfilePageHeader from '../pages/_layout/_headers/ProfilePageHeader';

const headers: RouteProps[] = [
	{ path: menuPages.login.path, element: null },
	{ path: menuPages.page404.path, element: null },
	{
		path: `*`,
		element: <ProfilePageHeader />,
	},
];

export default headers;
