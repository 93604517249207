import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, menuPages } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	SUMMARY: lazy(() => import('../pages/presentation/SummaryPage')),
};

const presentation: RouteProps[] = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: menuPages.login.path,
		element: <Login />,
	},
];
const contents = [...presentation];

export default contents;
