import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { OceanflixMiniLogo } from '../../../components/OceanflixLogo';
import Icon from '../../../components/icon/Icon';
import AuthContext from '../../../contexts/authContext';
import Aside, { AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Navigation from '../../../layout/Navigation/Navigation';
import { dashboardPagesMenu } from '../../../menu';

const OceanflixAside = () => {
	const { setUser } = useContext(AuthContext);
	const navigate = useNavigate();

	return (
		<Aside>
			<AsideHead>
				<OceanflixMiniLogo height={50} />
			</AsideHead>

			<Navigation menu={dashboardPagesMenu} id={'dashboard'} />

			<AsideFoot>
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								if (setUser) {
									setUser('');
								}
								navigate(`../login`);
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>Logout</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</AsideFoot>
		</Aside>
	);
};

export default OceanflixAside;
