import React from 'react';
import { RouteProps } from 'react-router-dom';
import { menuPages, pageLayoutTypesPagesMenu } from '../menu';
import OceanflixAside from '../pages/_layout/_asides/OceanflixAside';
// import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: menuPages.login.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: '*', element: <OceanflixAside /> },
	// { path: '*', element: <DefaultAside /> },
];

export default asides;
